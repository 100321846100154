  .with-fancybox body{
    overflow: hidden;
  }
  
  a[data-fancybox] img {
    cursor: zoom-in;
  }
  
  .fancybox__container {
    --fancybox-bg: #fff;
    --fancybox-color: #333;
    --fancybox-accent-color: #007178;
  
    --carousel-button-svg-filter: none;
  
    display: flex;
    flex-direction: row;


    flex-wrap: wrap;
    overflow: auto;
    background-color: #fff;
  }

  .fancybox__thumb{
    background-size: contain;
  }
  
  .fancybox__toolbar {
    position: relative;
  
    padding: 0;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(30px);
    border-bottom: 1px solid #edeef0;
  
    text-shadow: none;
    font-weight: 400;
  
    --carousel-button-svg-stroke-width: 1.75;
    --carousel-button-svg-width: 22px;
    --carousel-button-svg-height: 22px;

    position: fixed;
    top:0;
    left: 0;
  }
  
  .fancybox__slide {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .fancybox__slide.has-iframe,
  .fancybox__slide.has-pdf{

    &::after, 
    &::before {
      margin: 0;
    }

    .fancybox__content{
      padding-top: 0;
      padding-bottom: 0;
      height: 100%;
    }

  }

  .fancybox__slide.has-pdf{
    min-height: 90vh;
  }
  
  .fancybox__nav {
    --carousel-button-bg: #e5e7eb;
    --carousel-button-border-radius: 6px;
  
    --carousel-button-svg-width: 20px;
    --carousel-button-svg-height: 20px;
  
    --carousel-button-svg-stroke-width: 2.5;
  }
  
  .fancybox__leftCol {
    width: 80vw;
    min-height: 1px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;


    width: 100%;
    margin-top: 50px;
  }
  
  .fancybox__rightCol {
    width: 20vw;
    max-width: 300px;
    height: 100%;
    padding: 2rem;
    overflow: auto;
  
    display: flex;
    flex-direction: column;
  
    border-left: 1px solid #edeef0;
  
    opacity: var(--fancybox-opacity, 1);

    width: 100%;

  }
  
  /* Opening */
  .fancybox__container.is-animated[aria-hidden="false"] .fancybox__rightCol {
    animation: 0.15s ease backwards fancybox-fadeIn;
  }
  
  /* Closing */
  .fancybox__container.is-animated.is-closing .fancybox__rightCol {
    animation: 0.15s ease both fancybox-fadeOut;
  }
  
  .fancybox__container.is-animated.is-closing .fancybox__leftCol {
    overflow: visible;
  }


  /* Plyr.js */

  .plyr--full-ui{
    height: 100%;
  }




@media only screen and (max-width: 1023px) { 

  .fancybox__toolbar {
    position: fixed;
    top:0;
    left: 0;
  }

  .fancybox__leftCol {

    width: 100%;
    height: auto;
    margin-top: 100px;
  }

  .fancybox__rightCol{
    padding: 0;
    width: 90%;
    max-width: none;
    margin: 0 auto;
  } 
  
  .fancybox__carousel{
    max-width: 90%;
  }
  


  .fancybox__rightCol{
    padding: 0;
    width: 90%;
    margin: 0 auto;
  } 

}